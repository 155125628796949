import React from 'react'
import { graphql, PageProps } from 'gatsby'
import _get from 'lodash/get';

import withLocalization from 'decorators/withLocalization';
import PageLayout from 'components/Layout/PageLayout';

import ContentBox from 'widgets/ContentBox';
import { LandingPageQuery } from '../../generated/graphql-operations'

const LandingPage: React.FC<PageProps<LandingPageQuery>> = ({
  data: rawData
}) => {
  const data = rawData.sanityLandingPage
  const heroProps = {
    type: 'corner',
  }

  if (!data) {
    return null
  }

  return (
    <PageLayout data={data} heroProps={heroProps} cta={data.globalCta}>
      <ContentBox blocks={_get(data, '_rawContent')} />
    </PageLayout>
  )
}

export const query = graphql`
  query LandingPage($id: String, $language: String!) {
    sanityLandingPage(id: { eq: $id }) {
      hero {
        ...HeroFragment
      }
      title {
        localized
      }
      meta {
        ...MetaFragment
      }
      contact {
        ...ContactFragment
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      globalCta {
        ...CtaData
      }
    }
    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default withLocalization(LandingPage);
